/*
  This hook provides responsive window dimensions.

  SPECIAL THING: by default, window.innerWidth is always in pixels, and doesn't change as the user scales up their default font size. This isn't useful; if the user cranks their default font size to 32px, the window essentially shrinks by half, but `window.innerWidth` doesn't change.
  This hook solves for this. The values it returns are in pixels, but scaled based on the user’s default font size.
*/
import React from 'react';

import { throttle } from '@/utils';

interface WindowDimensions {
  width: number | undefined;
  height: number | undefined;
  clientWidth: number | undefined;
}

const useWindowDimensionsInRems = () => {
  const [windowDimensions, setWindowDimensions] =
    React.useState<WindowDimensions>({
      width: undefined,
      height: undefined,
      clientWidth: undefined,
    });

  React.useEffect(() => {
    const calculatorElement = document.createElement('div');
    calculatorElement.style.fontSize = '1rem';

    document.body.appendChild(calculatorElement);

    const fontSize = parseFloat(
      window.getComputedStyle(calculatorElement).fontSize
    );

    const ratio = 16 / fontSize;

    setWindowDimensions({
      width: window.innerWidth * ratio,
      height: window.innerHeight * ratio,
      clientWidth: document.documentElement.clientWidth * ratio,
    });

    const handleResize = throttle(() => {
      setWindowDimensions({
        width: window.innerWidth * ratio,
        height: window.innerHeight * ratio,
        clientWidth: document.documentElement.clientWidth * ratio,
      });
    }, 250);

    window.addEventListener('resize', handleResize);

    return () => {
      // Hm so weirdly, sometimes the `calculatorElement` is already removed from the DOM by the time this cleanup function runs. So we wrap this in a try/catch to prevent errors.
      try {
        document.body.removeChild(calculatorElement);
        window.removeEventListener('resize', handleResize);
      } catch (err) {}
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimensionsInRems;
