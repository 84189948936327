'use client';

import React from 'react';
import { styled } from '@linaria/react';

import { UserPreferencesContext } from '@/components/UserPreferencesProvider';
import FadeIn from '@/components/FadeIn';

// I have other moods, but I need to fix the dark mode images before I can use them. See instructions in /images/josh/__README.md
export type Mood = 'happy' | 'sad' | 'very-happy' | 'heart-eyes';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  colorModeOverride?: 'light' | 'dark';
  mood?: Mood;
  fadeDuration?: number;
  fadeDelay?: number;
}

function JericaMascot({
  colorModeOverride,
  mood = 'happy',
  fadeDuration = 600,
  fadeDelay = 200,
  ...delegated
}: Props) {
  const isUsingGlobalColorMode = !colorModeOverride;

  const { colorMode: globalColorMode } = React.useContext(
    UserPreferencesContext
  );

  const colorMode = colorModeOverride || globalColorMode;

  return (
    <Wrapper duration={fadeDuration} delay={fadeDelay} {...delegated}>
      <DarkLayer>
        <picture>
          <source
            type="image/webp"
            srcSet="/images/josh/josh-happy-dark.webp"
          />
          <MascotBase
            alt=""
            loading="lazy"
            src="/images/josh/josh-happy-dark.png"
          />
        </picture>
        {mood !== 'happy' && (
          <picture>
            <source
              type="image/webp"
              srcSet={`/images/josh/head-${mood}-dark.webp`}
            />
            <Head
              loading="lazy"
              src={`/images/josh/head-${mood}-dark.png`}
              alt=""
            />
          </picture>
        )}
      </DarkLayer>
      <LightLayer
        data-use-global-color-mode={isUsingGlobalColorMode}
        style={{ '--opacity': colorMode === 'dark' ? 0 : 1 }}
      >
        <picture>
          <source
            type="image/webp"
            srcSet="/images/josh/josh-happy-light.webp"
          />
          <MascotBase
            alt=""
            loading="lazy"
            src="/images/josh/josh-happy-light.png"
          />
        </picture>
        {mood !== 'happy' && (
          <picture>
            <source
              type="image/webp"
              srcSet={`/images/josh/head-${mood}-light.webp`}
            />
            <Head
              src={`/images/josh/head-${mood}-light.png`}
              loading="lazy"
              alt=""
            />
          </picture>
        )}
      </LightLayer>
    </Wrapper>
  );
}

const Wrapper = styled(FadeIn)`
  position: relative;
`;

const DarkLayer = styled.div`
  position: relative;
`;

const LightLayer = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: var(--opacity);
  transition: opacity calc(var(--color-swap-duration) + 500ms)
    var(--color-swap-timing-function);

  /*
    Ok there's some tricky business here.
    When this component first renders on the server, we don't know what the user’s chosen color mode is. If we specify an override, we don't care, but otherwise, we'll read the global color value from the HTML tag. That way, the correct mascot is shown from the very first paint.
  */
  html[data-color-mode='light'] &[data-use-global-color-mode='true'] {
    opacity: 1;
  }
  html[data-color-mode='dark'] &[data-use-global-color-mode='true'] {
    opacity: 0;
  }
`;

const MascotBase = styled.img`
  display: block;
  top: 0;
  left: 0;
  width: 137.5px;
  height: 232px;
  user-select: none;
  pointer-events: none;
`;

const Head = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* Dragging the face off is SUPER creepy */
  user-select: none;
  pointer-events: none;
`;

export default JericaMascot;
