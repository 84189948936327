// NOTE: This component duplicates a lot of the stuff in GlassyBackdrop, but I can't use that since I need hardcoded values for the CSS Custom Properties that will be transitioned.
'use client';

import * as React from 'react';
import { styled } from '@linaria/react';

import { USE_HAMBURGER_MENU_BELOW } from '@/constants';
import { DARK_COLORS, LIGHT_COLORS } from '@/constants/colors';
import useScrollPosition from '@/hooks/use-scroll-position';
import useFontRatio from '@/hooks/use-font-ratio';

import MaxWidthWrapper from '@/components/MaxWidthWrapper';
import SiteHeader from '@/components/SiteHeader';
// import USElectionHeader from '@/components/USElectionHeader';

import { SPACE_ABOVE_HEADER } from './Home.constants';

function HomepageSiteHeader() {
  const [, scrollY] = useScrollPosition(100);
  const fontRatio = useFontRatio();

  // The glassy headers on this site have a neat trick: they use a subtle gradient to fade out the stuff near the top of the header. This prevents the awkward flickering colors as elements leave the viewport and are no longer blurred by the backdrop filter.
  // The challenge with this specific component is that the header starts above the sky, which is a blue-green gradient, but as the user scrolls, the header sits above the cloud, which is white/black. If we keep the greenish-bluish gradient, it looks really funny.
  // So, once the user scrolls past a certain threshold, we'll swap the color being used for the fade, and use CSS Houdini to animate the transition.

  const isOverThreshold =
    typeof scrollY === 'number' && scrollY > 300 * fontRatio;

  return (
    <HeaderWrapper
      data-is-over-threshold={isOverThreshold ? 'true' : 'false'}
    >
      <Backdrop />
      {/* <USElectionHeader
        style={{
          marginTop: `-${SPACE_ABOVE_HEADER}rem`,
        }}
      /> */}
      <MaxWidthWrapper style={{ position: 'relative' }}>
        <SiteHeader includeLogoAnimation />
      </MaxWidthWrapper>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: ${SPACE_ABOVE_HEADER}rem;
  margin-inline: auto;
  background: linear-gradient(
    to top,
    transparent 0%,
    var(--top-color) 100%
  );
  /* HACK: Matching the --sky-from-duration from the homepage */
  transition: --top-color calc(var(--color-swap-duration) + 600ms)
    var(--color-swap-timing-function);

  html[data-color-mode='light'] & {
    @property --top-color {
      syntax: '<color>';
      inherits: true;
      initial-value: ${LIGHT_COLORS['--color-sky-from']};
    }

    --top-color: ${LIGHT_COLORS['--color-sky-from']};

    &[data-is-over-threshold='true'] {
      --top-color: ${LIGHT_COLORS['--color-background']};
      transition: --top-color calc(var(--color-swap-duration))
        var(--color-swap-timing-function);
    }
  }

  html[data-color-mode='dark'] & {
    @property --top-color {
      syntax: '<color>';
      inherits: true;
      initial-value: ${DARK_COLORS['--color-sky-from']};
    }
    --top-color: ${DARK_COLORS['--color-sky-from']};

    &[data-is-over-threshold='true'] {
      --top-color: ${DARK_COLORS['--color-background']};
      transition: --top-color calc(var(--color-swap-duration))
        var(--color-swap-timing-function);
    }
  }

  @media (max-width: ${USE_HAMBURGER_MENU_BELOW}rem) {
    margin-top: 0;
  }
`;

const Backdrop = styled.div`
  --extended-by: 100px;
  --cutoff: calc(100% - var(--extended-by));
  position: absolute;
  inset: 0;
  bottom: calc(var(--extended-by) * -1);
  -webkit-mask-image: linear-gradient(
    to bottom,
    black 0,
    black var(--cutoff),
    transparent var(--cutoff)
  );
  mask-image: linear-gradient(
    to bottom,
    black 0,
    black var(--cutoff),
    transparent var(--cutoff)
  );
  backdrop-filter: blur(8px);
  pointer-events: none;
  background: transparent;
  transition: opacity 600ms;
`;

export default HomepageSiteHeader;
