'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import { BREAKPOINTS, TIGHT_SPRING } from '@/constants';
import useSound from '@/hooks/use-sound';
import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';

import Link from '@/components/Link';

interface Props {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const CategoryPill = ({ href, onClick, children }: Props) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  const backgroundSpring = useSpring({
    opacity: isHovering ? 1 : 0.8,
    transform: isHovering ? `scale(1.06)` : `scale(0.99)`,
    config: TIGHT_SPRING,
    immediate: prefersReducedMotion,
  });

  const [playHoverSound, { stop }] = useSound(
    '/sounds/plunger-immediate.mp3',
    {
      volume: 0.15,
    }
  );

  const as = href ? Link : 'button';

  return (
    <LinkWrapper
      as={as}
      href={href}
      onMouseEnter={() => {
        setIsHovering(true);
        playHoverSound();
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        stop();
      }}
      onClick={onClick}
    >
      <Background style={backgroundSpring} />
      {children}
    </LinkWrapper>
  );
};

// NOTE: This won't actually render an <a>. It's overwritten by `as` in the component definition, either a Link or "button".
const LinkWrapper = styled.a`
  position: relative;
  display: inline-block;
  border-radius: 8px;
  padding: 0.3125rem 0.75rem;
  font-size: 0.875rem;
  color: inherit;
  text-decoration: none;
  isolation: isolate;

  @media ${BREAKPOINTS.smAndSmaller} {
    padding: 6px 18px;
    font-size: 1rem;
  }
`;

const Background = styled(animated.div)`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: var(--color-cloud-500);
  transform-origin: center center;
  transition: outline-color var(--color-swap-duration);
  outline: 1px solid transparent;
  outline-offset: -1px;

  html[data-color-mode='dark'] & {
    background-color: transparent;
    outline: 1px solid var(--color-gray-300);
  }
`;

export default CategoryPill;
