'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { ArrowDown } from 'react-feather';

import { HOMEPAGE_ARTICLE_LIMIT } from '@/constants';
import type { ContentData } from '@/types/content.types';

import ContentPreview from '@/components/ContentPreview';
import ButtonPlayful from '@/components/ButtonPlayful';
import Spinner from '@/components/Spinner';
import TextLink from '@/components/Link/TextLink';
import Toast from '@/components/Toast/LazyToast';

interface Props {
  initialArticles: Array<ContentData>;
}

function AllContentList({ initialArticles }: Props) {
  const [articles, setArticles] =
    React.useState<Array<ContentData>>(initialArticles);
  const [status, setStatus] = React.useState<
    'idle' | 'loading' | 'error' | 'success'
  >('idle');

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>
  ) {
    if (status === 'loading') {
      // Ignore multiple clicks
    }

    event.preventDefault();
    setStatus('loading');

    try {
      const response = await fetch('/api/retrieve-remaining-posts/');
      const json = await response.json();

      if (!response.ok || !json.articles) {
        setStatus('error');
        return;
      }

      setArticles((prevArticles) => [
        ...prevArticles,
        ...json.articles,
      ]);
      setStatus('success');
    } catch (err) {
      setStatus('error');
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      {articles.map((contentData, index) => {
        return (
          <ContentPreview
            key={contentData.slug}
            contentData={contentData}
            includeEnterAnimation={
              status === 'success' && index >= HOMEPAGE_ARTICLE_LIMIT
            }
          />
        );
      })}
      {status !== 'success' && (
        <ExpandWrapper onSubmit={handleSubmit}>
          <ExpandBtn
            Icon={status === 'loading' ? Spinner : ArrowDown}
            discouraged={status === 'loading'}
            boopEffect={{
              y: 3,
            }}
            backgroundColor="var(--button-bg)"
            highlightColor="var(--button-bg-highlight)"
          >
            Show more
          </ExpandBtn>
        </ExpandWrapper>
      )}
      {status === 'error' && (
        <Toast
          type="error"
          title="Could not fetch more articles"
          handleDismiss={() => setStatus('idle')}
          popTimeout={100}
        >
          Hmm, we weren’t able to reach the server to fetch additional
          articles. Sorry about that! Please check your internet
          connection and try again. If the problem persists, please{' '}
          <TextLink href="/contact">let me know</TextLink>.
        </Toast>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  scroll-margin-top: 96px;
`;

const ExpandWrapper = styled.form`
  position: relative;
  margin-top: -32px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 300px;
    background: linear-gradient(
      to top,
      var(--color-background) 0%,
      transparent 100%
    );
    pointer-events: none;
  }
`;

const ExpandBtn = styled(ButtonPlayful)`
  --button-bg: var(--color-gray-900);
  --button-bg-highlight: var(--color-gray-800);
  position: relative;
  width: 250px;
  max-width: 100%;
  margin: 0 auto;

  html[data-color-mode='dark'] & {
    --button-bg: var(--color-gray-200);
    --button-bg-highlight: var(--color-gray-300);
  }
`;

export default AllContentList;
