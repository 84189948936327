// The rainbow is not shown on mobile, so we should only load it for desktop users. This code conditionally renders the lazy-loaded HomepageConfigurableArt component.
// It does mean we lose the nice fade-in / fade-out when resizing, but that's a minor concern.

'use client';

import dynamic from 'next/dynamic';

import { BREAKPOINT_SIZES } from '@/constants';

import useWindowDimensions from '@/hooks/use-window-dimensions';

const HomepageConfigurableArt = dynamic(
  () => import('./HomepageConfigurableArt'),
  {
    ssr: false,
  }
);

function LazyConfigurableArt() {
  const windowDimensions = useWindowDimensions();

  if (
    typeof windowDimensions.width !== 'number' ||
    windowDimensions.width < BREAKPOINT_SIZES.md
  ) {
    return null;
  }

  return <HomepageConfigurableArt />;
}

export default LazyConfigurableArt;
